#home-page {

	@keyframes zoomInOut {
	  0%    { @include cp-property(transform, scale(1.0)); }
	  50%   { @include cp-property(transform, scale(1.15)); }
	  100%  { @include cp-property(transform, scale(1.0)); }
	}

	.intro-section {
		position: relative;
		@include flex-center-xy();
		width: 100%;
		height: 100vh;
		overflow: hidden;
		background-color: #000000;

		.bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-image: url('../images/bg.jpg');
			animation: zoomInOut 30s infinite;
		}

		a {
			color: #c8c8c8;
		}

		.box {
			position: relative;
			display: inline-block;
			padding: 15px 15px;
			width: 100%;
			color: #c8c8c8;
			text-align: center;
			z-index: 10;
		}

		.logo-2be {
			width: 268px;
			max-width: 100%;
		}

		.logo-b {
			width: 303px;
			max-width: 100%;
		}

		.p-extra-links {
			margin-top: 4rem;
		}

		.presented {
			font-size: 1.1rem;
			font-weight: 400;
		}

		.link-info {
			font-size: 1.15rem;
		}

		.btn-app {
			display: inline-block;

			img {
				height: 50px;
			}
		}

		@media screen and (max-width: 1399px) {
			.logo-2be {
				width: 240px;
			}

			.logo-b {
				width: 290px;
			}

			.p-extra-links {
				margin-top: 3rem;
			}
		}
	}

}
