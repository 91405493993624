#header {
	.navbar {
		background-color: #000000 !important;

		.navbar-brand {
			img {
				height: 80px;
			}
		}

		#nav-collapse {
			.navbar-nav {
				.nav-item {
					.nav-link {
						color: #ffffff;
						opacity: 1;
						font-weight: 500;
					}
				}
			}
		}
	}
}
