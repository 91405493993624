#contact-page{
  min-height: 81.2vh;

  .main-section{
    position: relative;
    width: 100%;
    overflow: hidden;

    .container{
      .col-form{
        padding-top: 45px;
        padding-bottom: 45px;
        align-self: center;

        .box-form{
          position: relative;
          width: 90%;

          &.left {
            margin-left: auto;
          }

          .title {
            font-size: 2rem;
            font-weight: 600;
          }

          form {
            margin-top: 25px;
          }

          .btn-send {
            display: inline-block;
            padding: 12px 15px;
            min-width: 205px;
            color: #ffffff;
            font-size: 1.05rem;
            font-weight: 600;
            border: 0 !important;
            border-radius: 5px;
            background-color: $red;

            &:hover {
              background-color: $red2;
            }
          }
        }
      }

      .col-image {
        position: relative;

        .image {
          position: absolute;
          top: 0;
          right: 15px;
          width: 50vw;
          height: 100%;
          background-color: $soft-gray;
          background-image: url('../images/pages/contact/image.jpg');
        }
      }

      .col-image-info {
        position: relative;

        .image {
          position: absolute;
          @include flex-center-xy();
          top: 0;
          left: 15px;
          width: 50vw;
          height: 100%;
          background-color: $soft-gray;
          background-image: url('../images/pages/franchises/image.jpg');

          .inside {
            display: inline-block;
            width: 70%;
            color: #ffffff;
            text-align: center;
            line-height: 1.3;
          }
        }
      }
    }

    @media screen and (max-width: 1399px){
      .container{
        .col-form{
          padding-top: 25px;
          padding-bottom: 25px;

          .box-form{
            width: 100%;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      .container{
        .col-image{
          padding-left: 0;
          padding-right: 0;
          height: 250px;

          .image{
            width: 100%;
            right: 0;
          }
        }

        .col-image-info {
          position: relative;
          order: 0;

          .image {
            position: relative;
            left: 0;
            width: 100%;

            .inside {
              padding: 20px 0px 0px 0px;
              width: 100%;
              font-size: 0.95rem;
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      .container{
        .col-image{
          height: 160px;
        }

        .col-form{
          padding-top: 15px;
          padding-bottom: 15px;

          .box-form{
            .btn-send{
              width: 100%;
            }
          }
        }
      }
    }
  }

}

.franchises-page {
  .main-section {
    @media screen and (max-width: 991px) {
      .col-form {
        order: 1;
      }

      .col-image-info {
        .image {
          background-image: none !important;
          background-color: #ffffff !important;

          .inside {
            color: #555658 !important;
          }
        }
      }
    }
  }
}
