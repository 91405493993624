#footer{
  position: relative;
  width: 100%;
  padding: 25px 15px;
  color: #ffffff;
  text-align: center;
  background-color: #000000;

  a {
    color: #ffffff;
  }
}
